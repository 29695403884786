<template>
  <div class="split-button">
    <el-button
      type="primary"
      v-if="mainButton.showButton"
      @click="handleMainButtonClick"
      class="main-button"
    >
      <i class="fa fa-floppy-o" aria-hidden="true"></i>
      {{ mainButton.name }}
    </el-button>
    <el-dropdown v-if="!disableDropdown" @command="handleDropdownCommand">
      <el-button type="primary" class="dropdown-toggle">
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="item in dropdownItems"
          :key="item.command"
          :command="item.command"
        >
          <i :class="item.icon" aria-hidden="true"></i>
          {{ item.text }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: 'SplitButton',
  props: {
    mainButton: {
      type: Object,
      default: () => {},
    },
    dropdownItems: {
      type: Array,
      default: () => [],
    },
    disableDropdown: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const handleMainButtonClick = () => {
      emit('mainButtonClick');
    };

    const handleDropdownCommand = (command) => {
      console.log('commad', command);
      emit('dropdownSelectEmit', command);
    };

    return {
      handleMainButtonClick,
      handleDropdownCommand,
    };
  },
};
</script>

<style scoped>
.split-button {
  display: flex;
  align-items: center;
}

.main-button {
  margin-right: -1px;
}

.dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.el-button--primary {
  border: none !important;
  border-radius: 0px !important;
  height: 32px !important;
  font-size: 12px;
  box-shadow: rgba(27, 72, 126, 0.13) 0px 0px 10px 2px !important;
  padding: 7px 10px;
}
</style>
