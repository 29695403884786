/* eslint-disable no-empty */
/* eslint-disable no-empty */
/* eslint-disable no-empty */
<template>
  <section class="all-documents-view">
    <el-row type="flex" justify="center" align="center">
      <el-dialog
        :visible.sync="documentActionModal"
        width="30%"
        class="doc-modal"
        v-loading="modalLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <span slot="footer" class="dialog-footer">
          <el-button @click="documentActionModal = false">Cancel</el-button>
          <el-button type="primary">Confirm</el-button>
        </span>
      </el-dialog>
      <el-col :span="24">
        <div class="d-flex mb-4 top-search-condition-bar-type-two">
          <div>
            <h3 class="title fw-normal text-dark mb-0 ls-05">{{hideLabel ? "" : 'Contacts'}}</h3>
          </div>
          <div class="actions-wrapper contacts-page">
            <div :style="this.state == 'opan' ? `` : `margin-left:120px`">
              <el-dropdown
                class="import-dropdown-btn"
                v-if="this.selectedContact.length"
              >
                <el-button size="mini" class="mr-2 el-dropdown-link px-2">
                  Delete
                  <i class="el-icon-arrow-down"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <a @click="centerDialogVisible = true">
                    <el-dropdown-item>
                      <i class="el-icon-delete"></i>
                      Delete
                    </el-dropdown-item>
                  </a>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="first-line">
              <label
                :data-state="state"
                for="search"
                class="search-icon-wrapper"
              >
                <input
                  v-model="search_string"
                  type="text"
                  @click="state = 'opan'"
                  @blur="state = 'close'"
                />
                <i
                  style="font-size: 120%; font-weight: bold; border: 2px"
                  class="el-icon-search"
                >
                </i>
              </label>
            </div>
            <div class="second-line">
              <el-dropdown
                class="import-dropdown-btn"
                v-if="checkPermissions('createContact')"
              >
                <el-button size="mini" class="el-dropdown-link px-2">
                  {{ getIsMobile ? "Import" : "Import contacts" }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu
                  slot="dropdown"
                  class="import-dropdown-btn-menu"
                >
                  <a @click="goToImportGmailContact">
                    <el-dropdown-item>
                      <img
                        src="@/assets/img/gmail.svg"
                        alt="icon"
                        class="icon-type-one gmail"
                      />
                      <span>Import from Gmail</span>
                    </el-dropdown-item>
                  </a>
                  <a @click="goToImportSpreadsheetContact">
                    <el-dropdown-item>
                      <img
                        src="@/assets/img/google_sheets.svg"
                        alt="icon"
                        class="icon-type-one gmail"
                      />
                      <span>Import from Spreadsheet</span>
                    </el-dropdown-item>
                  </a>
                  <a @click="goToImportOutlookContact">
                    <el-dropdown-item>
                      <img
                        src="@/assets/img/outlook.svg"
                        alt="icon"
                        class="icon-type-one gmail"
                      />
                      <span>Import from Outlook</span>
                    </el-dropdown-item>
                  </a>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                v-if="checkPermissions('createContact')"
                class="create-btn px-4 ml-2"
                type="primary"
                @click="goToCreateContact"
              >
              <i class="fa fa-plus-circle"></i>
                {{ getIsMobile ? "Create" : "Create Contact" }}
              </el-button>
            </div>
          </div>
        </div>
        <div
          v-loading="loading"
          class="vue-data-table-default"
          element-loading-text="Loading..."
        >
          <data-tables-server
            v-loading="configureLoading"
            :fullscreen="true"
            :data="data"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            @query-change="loadData"
            @sort-change="sortChange"
            style="width: 100%"
            v-if="!getIsMobile"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>

            <el-table-column
              label="Name"
              id="name"
              prop="first_name"
              min-width="250"
              sortable="custom"
              fixed="left"
            >
              <template slot-scope="scope">
                <div class="icon-block initial">
                  <div class="icon avatar-icon-in-table">
                    <div class="initial-text">
                      <span>{{ scope.row | getUserNameAvatar }}</span>
                    </div>
                  </div>
                  <div class="icon-text">
                    {{ scope.row | getUserFullName }}
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Type" width="250">
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.contact_types && scope.row.contact_types.length
                  "
                >
                  <el-tag
                    :type="
                      scope.row.contact_types[0] &&
                      scope.row.contact_types[0].contact_type.entity_type ==
                        'BUSINESS'
                        ? 'success'
                        : ''
                    "
                  >
                    {{ scope.row.contact_types[0].contact_type.name }}
                  </el-tag>
                  <el-popover trigger="hover">
                    <div
                      v-for="(ct, i) in scope.row.contact_types"
                      :key="i + scope.row._id"
                      :type="
                        ct.contact_type &&
                        ct.contact_type.entity_type == 'BUSINESS'
                          ? 'success'
                          : ''
                      "
                    >
                      <p style="font-weight: bold">
                        {{ i + 1 }}.{{ ct.contact_type.name }}
                      </p>
                    </div>
                    <el-tag
                      v-if="scope.row.contact_types.length > 1"
                      slot="reference"
                      style="
                        border-radius: 50% !important;
                        margin-left: 5px !important;
                      "
                    >
                      {{ scope.row.contact_types.length - 1 + "+" }}
                    </el-tag>
                  </el-popover>
                </div>
                <div
                  v-else-if="
                    scope.row.contact_type && scope.row.contact_type.name
                  "
                >
                  <el-tag
                    :type="
                      scope.row.contact_type &&
                      scope.row.contact_type.entity_type == 'BUSINESS'
                        ? 'success'
                        : ''
                    "
                  >
                    {{ scope.row.contact_type.name }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="email"
              label="Email"
              sortable="custom"
              width="280"
            ></el-table-column>
            <el-table-column
              :default-sort="{ prop: 'phone_number', order: 'descending' }"
              prop="phone_number"
              label="Phone"
              sortable
              width="150"
            ></el-table-column>
            <el-table-column
              prop="address"
              label="Address"
              sortable="custom"
              width="200"
            ></el-table-column>
            <el-table-column
              prop="title"
              id="title"
              sortable="custom"
              label="Title"
              width="200"
            ></el-table-column>
            <el-table-column
              prop="updated_at"
              label="Last Modified"
              width="200"
              sortable
              :sort-method="updatedDateSort"
            >
              <template slot-scope="scope"
                >{{ scope.row.updated_at | globalDateFormat }}
              </template>
            </el-table-column>
            <template v-if="getUserType">
              <el-table-column
                prop="created_by"
                label="Created By"
                sortable
                width="150"
              >
                <template slot-scope="scope">
                  {{
                    getAuthenticatedUser._id.toString() ==
                    scope.row.created_by._id.toString()
                      ? "SELF"
                      : scope.row.created_by.first_name +
                        " " +
                        scope.row.created_by.last_name
                  }}
                </template>
              </el-table-column>
            </template>
            <!-- <el-table-column
              prop="updated_at"
              label="Last Modified"
              width="200"
            >
              <template slot-scope="scope">
                {{scope.row.updated_at}}
              </template>
            </el-table-column> -->
            <el-table-column
              prop="action"
              label="Actions"
              class="action"
              align="center"
              width="200"
              fixed="right"
              v-if="
                checkPermissions('viewContactDocuments') ||
                checkPermissions('editContact') ||
                checkPermissions('deleteContact')
              "
            >
              <template slot-scope="scope">
                <ul class="action-buttons" align="center">
                  <li
                    v-if="checkPermissions('viewContactDocuments')"
                    class="first-button"
                  >
                    <el-button
                      plain
                      size="mini"
                      type="primary"
                      class="px-2 ml-2"
                      title="View Document"
                      @click="goToDocuments(scope.row)"
                    >
                      <i class="el-icon-document"></i>
                    </el-button>
                  </li>
                  <li
                    v-if="checkPermissions('editContact')"
                    class="second-button"
                  >
                    <el-button
                      plain
                      type="warning"
                      size="mini"
                      class="px-2 ml-2"
                      @click="onEdit(scope.row)"
                      title="Edit Contact"
                    >
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </li>
                  <li
                    v-if="checkPermissions('deleteContact')"
                    class="third-button"
                  >
                    <template
                      v-if="
                        getAuthenticatedUser._id &&
                        scope.row.created_by._id &&
                        (getAuthenticatedUser._id.toString() ==
                          scope.row.created_by._id.toString() ||
                          getUserType.name == 'ADMIN' ||
                          getUserType.name == 'OWNER')
                      "
                    >
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2 ml-2"
                        @click="onDelete(scope.row._id)"
                      >
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </template>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </data-tables-server>

          <data-tables-server
            v-loading="configureLoading"
            :fullscreen="true"
            :data="data"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            @query-change="loadData"
            style="width: 100%"
            v-if="getIsMobile"
            class="contacts-list-table"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Type:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.contact_type.name }}
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Email:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.email }}
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Phone:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.phone_number }}
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Address:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.address }}
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Last Modified:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.updated_at }}
                  </div>
                </div>
                <template
                  v-if="
                    getUserType &&
                    (getUserType.name == 'ADMIN' || getUserType.name == 'OWNER')
                  "
                >
                  <div class="expand-column-item">
                    <div class="expand-column-item-label">Created By:</div>
                    <div class="expand-column-item-content">
                      {{
                        scope.row.created_by.first_name +
                        " " +
                        scope.row.created_by.last_name
                      }}
                    </div>
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="Name" id="name" fixed="left">
              <template slot-scope="scope">
                <div class="icon-block initial">
                  <div class="icon avatar-icon-in-table">
                    <div class="initial-text">
                      <span>{{ scope.row | getUserNameAvatar }}</span>
                    </div>
                  </div>
                  <div class="icon-text">
                    {{ scope.row | getUserFullName }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label="Actions"
              class="action"
              align="right"
              fixed="right"
            >
              <template slot-scope="scope">
                <ul class="action-buttons" style="flex-direction: column">
                  <li
                    v-if="checkPermissions('viewContactDocuments')"
                    class="first-button"
                  >
                    <el-button
                      plain
                      size="mini"
                      type="primary"
                      class="px-2 ml-2"
                      title="View Document"
                      @click="goToDocuments(scope.row)"
                    >
                      <i class="el-icon-document"></i>
                    </el-button>
                  </li>
                  <li
                    v-if="checkPermissions('editContact')"
                    class="second-button"
                  >
                    <el-button
                      plain
                      type="warning"
                      size="mini"
                      class="px-2 ml-2"
                      @click="onEdit(scope.row)"
                      title="Edit Contact"
                    >
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </li>
                  <li
                    v-if="checkPermissions('deleteContact')"
                    class="third-button"
                  >
                    <template
                      v-if="
                        getAuthenticatedUser._id &&
                        scope.row.created_by._id &&
                        (getAuthenticatedUser._id.toString() ==
                          scope.row.created_by._id.toString() ||
                          getUserType.name == 'ADMIN' ||
                          getUserType.name == 'OWNER')
                      "
                    >
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2 ml-2"
                        @click="onDelete(scope.row._id)"
                      >
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </template>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </data-tables-server>
        </div>
      </el-col>
    </el-row>

    <!-- result popup of Importing contacts -->
    <el-dialog
      :visible.sync="viewImportingContactsResultPopup"
      class="outersize-confirm-type-three importing-result-popup"
      title="Importing Result"
      width="100%"
    >
      <el-row :gutter="30">
        <el-col :lg="{ span: 23, offset: 0 }">
          <div class="result-row">
            <span class="title bold larger-font-size">
              Total imported contacts are:
            </span>
            <span class="value">
              {{ importTotalContactsCnt }}
            </span>
          </div>
          <div class="result-row indent">
            <span class="title"> Contacts added: </span>
            <span class="value">
              {{ importAddedContactsCnt }}
            </span>
          </div>
          <div class="result-row indent">
            <span class="title"> Contacts updated: </span>
            <span class="value">
              {{ importUpdatedContactsCnt }}
            </span>
          </div>
          <div class="result-row failed indent">
            <span class="title"> Contacts failed: </span>
            <span class="value">
              {{ importFailedContactsCnt }}
            </span>
          </div>
          <!-- <div class="result-row error-details-title">
            <span class="title bold larger-font-size"> Error Details: </span>
          </div> -->
          <div
            class="error-details indent"
            v-for="item in importErrorDetails"
            :key="item"
            :value="item"
          >
            {{ item }}
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog
      class="DialogueStyle"
      title="Warning"
      :visible.sync="centerDialogVisible"
      width="27%"
      center
    >
      <span v-if="this.selectedContact.length == 1"
        >Are you sure you want to delete 1 selected Contact?</span
      >
      <span
        v-if="
          this.selectedContact.length == 0 || this.selectedContact.length > 1
        "
        >Are you sure you want to delete
        {{ this.selectedContact.length }} selected Contacts?</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="selectedContactDelete"
          >Confirm</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="Import Contacts From Gmail"
      :visible.sync="showImportContactsPopup"
      :width="getIsMobile ? '100%' : '60%'"
      class="doc-modal import-contacts-popup"
      :show-close="true"
      :before-close="handleClosePopup"
    >
      <!-- :before-close="resetBulkModal" -->
      <div>
        <el-tabs v-model="activeNameForImport">
          <el-tab-pane
            :label="`All Contacts ${successfullCSVData.length}`"
            name="success"
          >
            <el-scrollbar wrap-style="">
              <div class="import-contacts-table-wrapper">
                <el-table
                  :data="getCurrentPageOfSuccessfullCSVData"
                  style="width: 100%"
                  @cell-mouse-enter="editSuccessCSVData"
                  @cell-mouse-leave="resetSuccessCSVData"
                  row-key="id"
                  class="import-contacts-table"
                  ref="multipleTableOfSpreadSheet"
                >
                  <el-table-column
                    v-for="(row, index) in primaryHeaders"
                    :key="index"
                    :label="row"
                    width="220"
                  >
                    <template slot-scope="scope">
                      <div
                        v-if="currentActiveSuccessRowIndex == scope.row['id']"
                      >
                        <el-input
                          size="mini"
                          v-model="scope.row[row]"
                          v-if="row != 'contact_type'"
                        ></el-input>
                        <el-select
                          v-model="scope.row[row]"
                          placeholder="Select contact type"
                          filterable
                          default-first-option
                          class="filter-contact-type"
                          v-if="row == 'contact_type'"
                        >
                          <el-option
                            v-for="(type, index) in getAllContactTypes"
                            :key="index + 1"
                            :value="type.name"
                            :label="type.name"
                          >
                            {{ type.name }}
                          </el-option>
                        </el-select>
                      </div>
                      <div v-else>{{ scope.row[row] }}</div></template
                    >
                  </el-table-column>
                </el-table>
              </div>
            </el-scrollbar>
            <el-pagination
              :current-page.sync="currentPageForIC"
              :page-sizes="paginationPropsForIC"
              :page-size="pageSizeForIC"
              layout="prev, pager, next, jumper, total"
              :total="totalForIC"
            ></el-pagination>
            <!-- layout="prev, pager, next, jumper, sizes, total" -->
          </el-tab-pane>
          <el-tab-pane
            :label="`Errors ${errorfullCSVData.length}`"
            name="error"
          >
            <el-scrollbar wrap-style="">
              <div class="import-contacts-table-wrapper">
                <el-table
                  :data="getCurrentPageOfErrorfullCSVData"
                  style="width: 100%"
                  @cell-mouse-enter="editErrorCSVData"
                  @cell-mouse-leave="resetErrorCSVData"
                  row-key="id"
                  class="import-contacts-table"
                >
                  <el-table-column
                    v-for="(row, index) in primaryHeaders"
                    :key="index"
                    :label="row"
                    width="220"
                  >
                    <template slot-scope="scope">
                      <div v-if="currentActiveRowIndex == scope.row['id']">
                        <el-input
                          size="mini"
                          v-model="scope.row[row]"
                          v-if="row != 'contact_type'"
                        ></el-input>
                        <el-select
                          v-model="scope.row[row]"
                          placeholder="Select contact type"
                          filterable
                          default-first-option
                          class="filter-contact-type"
                          v-if="row == 'contact_type'"
                        >
                          <el-option
                            v-for="(type, index) in getAllContactTypes"
                            :key="index + 1"
                            :value="type.name"
                            :label="type.name"
                          >
                            {{ type.name }}
                          </el-option>
                        </el-select>
                      </div>
                      <div v-else>
                        <p
                          v-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'phone' &&
                            !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                              scope.row[row]
                            ) &&
                            scope.row[row].length >= 1
                          "
                          style="color: red"
                        >
                          Invalid phone
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'company'
                          "
                        >
                          {{ scope.row[row] }}
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'address'
                          "
                        >
                          {{ scope.row[row] }}
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            (row == 'first_name' || row == 'last_name') &&
                            (scope.row[row].length < 1 ||
                              !checkNameValidation(scope.row[row]))
                          "
                          style="color: red"
                        >
                          Invalid string or less than 1
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'email' &&
                            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
                              scope.row[row]
                            ) &&
                            scope.row[row].length >= 3
                          "
                          style="color: red"
                        >
                          <!-- !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test( -->
                          Invalid email
                        </p>
                        <p
                          v-else-if="
                            scope.row[row] &&
                            scope.row[row].length &&
                            row == 'phone_number' &&
                            !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                              scope.row[row]
                            ) &&
                            scope.row[row].length >= 1
                          "
                          style="color: red"
                        >
                          Invalid Phone Number
                        </p>
                        <p v-else-if="scope.row[row] && scope.row[row].length">
                          {{ scope.row[row] }}
                        </p>
                        <p
                          v-else-if="
                            row == 'first_name' ||
                            row == 'last_name' ||
                            row == 'email'
                          "
                          style="color: red"
                        >
                          Missing Value
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-scrollbar>
            <el-pagination
              :current-page.sync="currentPageForICError"
              :page-sizes="paginationPropsForICError"
              :page-size="pageSizeForICError"
              layout="prev, pager, next, jumper, total"
              :total="totalForICError"
            ></el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" v-if="uploadedCSVDataResult.length">
        <!-- <el-button @click="resetCSVData">Re-Upload CSV</el-button> -->
        <el-button
          type="danger"
          :disabled="successfullCSVData && !successfullCSVData.length"
          @click="importContacts"
        >
          Import Contacts
        </el-button>
      </span>
    </el-dialog>

    <!-- :before-close="resetBulkModal" -->
    <el-dialog
      title="Import Contacts from Spreadsheet"
      :visible.sync="importFromSSActionModal"
      :width="getIsMobile ? '100%' : '60%'"
      class="doc-modal"
      :show-close="true"
      :before-close="handleClosePopup"
    >
      <div>
        <el-steps
          :space="300"
          :active="activeStep"
          finish-status="success"
          align-center
          v-if="getBulkSendPercentage != 100 && !isContactsCreated"
          class="bulk-step"
        >
          <el-step title="Create CSV"> </el-step>
          <el-step title="Upload your CSV"> </el-step>
        </el-steps>
        <el-divider></el-divider>
        <div v-if="activeStep == 0">
          <div style="display: flex" class="download-csv-button-wrapper">
            <p>
              Here is a CSV format to use. Download it and fill with your data.
            </p>
            <el-button
              size="mini"
              @click="generateCSVFile"
              class="download-csv ml-3"
              >Download Blank CSV</el-button
            >
          </div>
        </div>
        <div v-if="activeStep == 1" style="text-align: center">
          <div v-if="!uploadedCSVDataResult.length">
            <el-upload
              class="upload-csv-file"
              drag
              name="logo"
              :on-change="uploadBulkSendCSV"
              action
              :show-file-list="false"
              :auto-upload="false"
              accept=".csv"
            >
              <div class="upload-file">
                <div class="mt-2">
                  <img
                    src="@/assets/img/icons/upload-file.svg"
                    alt="Upload File"
                    height="30"
                  />
                </div>
                <p>Drag &amp; Drop CSV file here</p>
                <el-button
                  size="mini"
                  style="
                    background-color: transparent;
                    border-color: #f754a2;
                    color: #f754a2;
                    border-radius: 0;
                  "
                  >Import Contacts List</el-button
                >
              </div>
            </el-upload>
          </div>
          <div v-else>
            <el-tabs v-model="activeNameForImport">
              <el-tab-pane
                :label="`All Contacts ${successfullCSVData.length}`"
                name="success"
              >
                <el-scrollbar wrap-style="">
                  <div class="import-contacts-table-wrapper">
                    <el-table
                      :data="getCurrentPageOfSuccessfullCSVData"
                      style="width: 100%"
                      @cell-mouse-enter="editSuccessCSVData"
                      @cell-mouse-leave="resetSuccessCSVData"
                      row-key="id"
                      class="import-contacts-table"
                    >
                      <el-table-column
                        v-for="(row, index) in primaryHeaders"
                        :key="index"
                        :label="row"
                        width="220"
                      >
                        <template slot-scope="scope">
                          <div
                            v-if="
                              currentActiveSuccessRowIndex == scope.row['id']
                            "
                          >
                            <el-input
                              size="mini"
                              v-model="scope.row[row]"
                              v-if="row != 'contact_type'"
                            ></el-input>
                            <el-select
                              v-model="scope.row[row]"
                              placeholder="Select contact type"
                              filterable
                              default-first-option
                              class="filter-contact-type"
                              v-if="row == 'contact_type'"
                            >
                              <el-option
                                v-for="(type, index) in getAllContactTypes"
                                :key="index + 1"
                                :value="type.name"
                                :label="type.name"
                              >
                                {{ type.name }}
                              </el-option>
                            </el-select>
                          </div>
                          <div v-else>{{ scope.row[row] }}</div></template
                        >
                      </el-table-column>
                    </el-table>
                  </div>
                </el-scrollbar>
                <el-pagination
                  @size-change="handleSizeChangeForIC"
                  @current-change="handleCurrentChangeForIC"
                  :current-page.sync="currentPageForIC"
                  :page-sizes="paginationPropsForIC"
                  :page-size="pageSizeForIC"
                  layout="prev, pager, next, jumper, total"
                  :total="totalForIC"
                ></el-pagination>
                <!-- layout="prev, pager, next, jumper, sizes, total" -->
              </el-tab-pane>
              <el-tab-pane
                :label="`Errors ${errorfullCSVData.length}`"
                name="error"
              >
                <el-scrollbar wrap-style="">
                  <div class="import-contacts-table-wrapper">
                    <el-table
                      :data="getCurrentPageOfErrorfullCSVData"
                      style="width: 100%"
                      @cell-mouse-enter="editErrorCSVData"
                      @cell-mouse-leave="resetErrorCSVData"
                      row-key="id"
                      class="import-contacts-table"
                    >
                      <el-table-column
                        v-for="(row, index) in primaryHeaders"
                        :key="index"
                        :label="row"
                        width="220"
                      >
                        <template slot-scope="scope">
                          <div v-if="currentActiveRowIndex == scope.row['id']">
                            <el-input
                              size="mini"
                              v-model="scope.row[row]"
                              v-if="row != 'contact_type'"
                            ></el-input>
                            <el-select
                              v-model="scope.row[row]"
                              placeholder="Select contact type"
                              filterable
                              default-first-option
                              class="filter-contact-type"
                              v-if="row == 'contact_type'"
                            >
                              <el-option
                                v-for="(type, index) in getAllContactTypes"
                                :key="index + 1"
                                :value="type.name"
                                :label="type.name"
                              >
                                {{ type.name }}
                              </el-option>
                            </el-select>
                          </div>
                          <div v-else>
                            <p
                              v-if="
                                scope.row[row] &&
                                scope.row[row].length &&
                                row == 'phone' &&
                                !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                                  scope.row[row]
                                ) &&
                                scope.row[row].length >= 1
                              "
                              style="color: red"
                            >
                              Invalid phone
                            </p>
                            <p
                              v-else-if="
                                scope.row[row] &&
                                scope.row[row].length &&
                                row == 'company'
                              "
                            >
                              {{ scope.row[row] }}
                            </p>
                            <p
                              v-else-if="
                                scope.row[row] &&
                                scope.row[row].length &&
                                row == 'address'
                              "
                            >
                              {{ scope.row[row] }}
                            </p>
                            <p
                              v-else-if="
                                scope.row[row] &&
                                scope.row[row].length &&
                                (row == 'first_name' || row == 'last_name') &&
                                (scope.row[row].length < 1 ||
                                  !checkNameValidation(scope.row[row]))
                              "
                              style="color: red"
                            >
                              Invalid string or less than 1
                            </p>
                            <p
                              v-else-if="
                                scope.row[row] &&
                                scope.row[row].length &&
                                row == 'email' &&
                                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
                                  scope.row[row]
                                ) &&
                                scope.row[row].length >= 3
                              "
                              style="color: red"
                            >
                              Invalid email
                            </p>
                            <p
                              v-else-if="
                                scope.row[row] &&
                                scope.row[row].length &&
                                row == 'phone_number' &&
                                !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                                  scope.row[row]
                                ) &&
                                scope.row[row].length >= 1
                              "
                              style="color: red"
                            >
                              Invalid Phone Number
                            </p>
                            <p
                              v-else-if="
                                scope.row[row] && scope.row[row].length
                              "
                            >
                              {{ scope.row[row] }}
                            </p>
                            <p
                              v-else-if="
                                row == 'first_name' ||
                                row == 'last_name' ||
                                row == 'email'
                              "
                              style="color: red"
                            >
                              Missing Value
                            </p>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-scrollbar>
                <el-pagination
                  @size-change="handleSizeChangeForICError"
                  @current-change="handleCurrentChangeForICError"
                  :current-page.sync="currentPageForICError"
                  :page-sizes="paginationPropsForICError"
                  :page-size="pageSizeForICError"
                  layout="prev, pager, next, jumper, total"
                  :total="totalForICError"
                ></el-pagination>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <span slot="footer" v-if="activeStep == 0">
        <el-button @click="importFromSSActionModal = false">Cancel</el-button>
        <el-button type="danger" @click="activeStep = 1">Continue</el-button>
      </span>
      <span
        slot="footer"
        v-if="activeStep == 1 && uploadedCSVDataResult.length"
      >
        <el-button @click="reuploadCSV">Re-Upload CSV</el-button>
        <el-button
          type="danger"
          :disabled="successfullCSVData && !successfullCSVData.length"
          @click="importContacts"
        >
          Import Contacts
        </el-button>
      </span>
      <span
        slot="footer"
        v-if="activeStep == 2 && !isContactsCreated && !docSettingsVisible"
      >
        <el-button @click="activeStep = 1">Back</el-button>
        <el-button
          type="danger"
          :loading="bulkSendBtnLoding"
          @click="checkSettingsVisibleOrNot"
          >{{ `Send documents` }}</el-button
        >
      </span>
    </el-dialog>

    <dialog-component
      :title="isEdit ? `Update Contact Info` : `Add Contact Info`"
      :visible="addContactPopUp"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      class="doc-modal"
      v-loading="modalLoading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      @before-close="addContactPopUp=false"
    >
      <el-form :model="contactFormData" class="py-3 px-4">
        <!-- {{ contactFormData }} -->
        <el-form-item
          class="mb-05"
          label="First Name"
          prop="first_name"
          :required="false"
        >
          <el-input v-model="contactFormData.first_name"></el-input>
          <p
            class="error"
            v-if="
              getContactErrors &&
              getContactErrors.first_name &&
              getContactErrors.first_name != 'First Name is Required!'
            "
          >
            {{ getContactErrors.first_name }}
          </p>
        </el-form-item>
        <el-form-item
          class="mb-05"
          label="Last Name"
          prop="last_name"
          :required="false"
        >
          <el-input v-model="contactFormData.last_name"></el-input>
          <p
            class="error"
            v-if="
              getContactErrors &&
              getContactErrors.last_name &&
              getContactErrors.last_name != 'Last Name is Required!'
            "
          >
            {{ getContactErrors.last_name }}
          </p>
        </el-form-item>
        <el-form-item class="mb-05" label="Email" prop="email" :required="false">
          <el-input
            v-model="contactFormData.email"
            :disabled="isEdit"
          ></el-input>
          <p
            class="error"
            v-if="
              getContactErrors &&
              getContactErrors.email &&
              getContactErrors.email != 'Email is Required!'
            "
          >
            {{ getContactErrors.email }}
          </p>
          <p
            class="error"
            v-if="getContactErrors && getContactErrors.critical_error"
          >
            {{ getContactErrors.critical_error }}
          </p>
        </el-form-item>
      </el-form>
        <!-- <el-row :gutter="30"> </el-row> -->
        <span slot="footer">
              <el-button
                style=""
                type="primary"
                class="mt-38 action-button"
                @click="updateContact"
                v-if="isEdit"
                :disabled="
                  !contactFormData.first_name ||
                  !contactFormData.last_name ||
                  !contactFormData.email
                "
                :loading="loading"
                >Update Contact</el-button
              >
              <el-button
                type="primary"
                class="mt-38 save-contact-button action-button"
                :disabled="
                  !contactFormData.first_name ||
                  !contactFormData.last_name ||
                  !contactFormData.email
                "
                @click="addContactInfo"
                :loading="loading"
                v-else
                >Save Contact</el-button
              >&nbsp;&nbsp;
              <el-popconfirm
                title="Are you sure you want to cancel?"
                @confirm="addContactPopUp = false"
                confirm-button-text="Yes"
                cancel-button-text="No"
              >
                <el-button slot="reference" class="action-button" plain
                  >Cancel</el-button
                >
              </el-popconfirm>
        </span>
      
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="addContactPopUp = false">Cancel</el-button>
        <el-button type="primary">Confirm</el-button>
      </span> -->
    </dialog-component>
  </section>
</template>

<script>
import axios from "@/config/axios";
import { mapGetters } from "vuex";
// import { AddEntityContact } from "./AddEntityContact.vue";

export default {
  name: "Entity-EntityContacts",
  components: {
    // AddEntityContact,
  },
  props: [
    "pageSizes",
    "type",
    "categoryId",
    "groupId",
    "search_string",
    "contactType",
    "accountName",
    "entityDataId",
    "hideLabel"
  ],
  data() {
    return {
      centerDialogVisible: false,
      selectedContact: [],

      client: null,
      userPrimaryDetailsForSS: [
        "first_name",
        "last_name",
        "email",
        "phone_number",
        "address",
        "company_name",
        "title",
      ],
      activeStep: 0,
      order_type: null,
      order_by: null,
      importFromSSActionModal: false,
      activeNameForImportFromSS: "success",

      totalForIC: 0,
      currentPageForIC: 1,
      pageSizeForIC: 5,
      totalForICError: 0,
      currentPageForICError: 1,
      pageSizeForICError: 5,

      filter_contact_type: "",
      // filtered_data: [],
      activeNameForImport: "success",
      state: "close",
      searchedContacts: [],
      searchedContact: " ",
      contactUsers: [],
      currentActiveRowIndex: -1,
      currentActiveSuccessRowIndex: -1,
      primaryHeaders: [],
      uploadedCSVDataResult: [],
      successfullCSVData: [],
      errorfullCSVData: [],
      showImportContactsPopup: false,

      importTotalContactsCnt: 0,
      importAddedContactsCnt: 0,
      importUpdatedContactsCnt: 0,
      importFailedContactsCnt: 0,
      importErrorDetails: [],
      viewImportingContactsResultPopup: false,
      allContacts: [],
      contactdeletionloading: false,
      loading: false,
      loadingText: "Loading...",
      modalLoading: false,
      isVisible: true,
      documentActionModal: false,
      editDailogVisible: false,
      selectedCompanyDocument: "",
      total: 0,
      currentPage: 1,
      pageSize: 0,
      data: [],
      configureLoading: false,
      openSendDocumentsModal: false,
      documentUsers: [],
      showSendDocumentoUserSettings: false,
      enforceSignatureOrder: null,
      searchedContactForSend: " ",
      // search_string: " ",
      sort: " ",
      isContactsCreated: false,
      getBulkSendPercentage: 0,

      addContactPopUp: false,

      contactFormData: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        address: "",
        company_name: "",
        contact_type: "",
        title: "",
        account_data_id: "",
        contact_types: []
      },
      isEdit: false,
      contact_id: null,
      rules: {
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Invalid Email",
            trigger: "blur",
          },
        ],
        first_name: [
          {
            required: true,
            message: "First name is required",
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: "Last name is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    getCurrentPageOfErrorfullCSVData() {
      const from = (this.currentPageForICError - 1) * this.pageSizeForICError;
      let to = from + this.pageSizeForICError;
      if (to > this.totalForICError) {
        to = this.totalForICError;
      }
      // console.log(
      //   ">>> getCurrentPageOfSuccessfullCSVData(), from, to, this.successfullCSVData.slice(from, to)",
      //   from,
      //   to,
      //   this.errorfullCSVData.slice(from, to)
      // );
      return this.errorfullCSVData.slice(from, to);
    },
    paginationPropsForICError() {
      return [5, 10, 20, 50];
    },
    getCurrentPageOfSuccessfullCSVData() {
      const from = (this.currentPageForIC - 1) * this.pageSizeForIC;
      let to = from + this.pageSizeForIC;
      if (to > this.totalForIC) {
        to = this.totalForIC;
      }
      // console.log(
      //   ">>> getCurrentPageOfSuccessfullCSVData(), from, to, this.successfullCSVData.slice(from, to)",
      //   from,
      //   to,
      //   this.successfullCSVData.slice(from, to)
      // );
      return this.successfullCSVData.slice(from, to);
    },
    paginationPropsForIC() {
      return [5, 10, 20, 50];
    },
    ...mapGetters("contacts", [
      "getAllContacts",
      "getContactDeleteStatus",
      "getCreateContactStatus",
      "getContactErrors",
      "getUpdateContactStatus",
      "getContact",
    ]),
    ...mapGetters("contactTypes", [
      "getAllContactTypes",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [5, 10, 20, 50],
      };
    },
    isImage() {
      return (type) => (type && type.indexOf("image") > -1 ? true : false);
    },
    isPdf() {
      return (type) => (type && type.indexOf("pdf") > -1 ? true : false);
    },
    fetchContactEmails() {
      // return (this.successfullCSVData || []).flatMap(e => e.email);
      return (email) =>
        email &&
        this.successfullCSVData.filter((e) => e.email == email).length > 1
          ? false
          : true;
    },
    fetchErrorContactEmails() {
      // return (this.successfullCSVData || []).flatMap(e => e.email);
      return (email) =>
        email &&
        this.successfullCSVData.filter((e) => e.email == email).length == 0
          ? false
          : true;
    },
  },
  async mounted() {
    this.contactFormData.account_data_id = this.accountName;
    await this.fetchContacts();
    try {
      var self = this;
      var elParentArr = document.getElementsByClassName("doc-modal");
      for (var i = 0; i < elParentArr.length; i++) {
        var el = elParentArr[i].getElementsByClassName(
          "el-dialog__headerbtn"
        )[0];
        el.addEventListener("click", () => {
          // self.isViewAllNotificationsPopupShow = false;
          document.querySelector("body").classList.remove("modal-open");
          self.resetBulkModal();
        });
      }
    } catch {
      // console.log('>>> mounted(), err');
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.selectedContact = [];
      this.selectedContact = val;
    },
    async selectedContactDelete() {
      this.centerDialogVisible = false;
      this.selectedContact.forEach(async (e, index) => {
        this.loading = true;
        await this.$store.dispatch("contacts/deleteContact", e._id);
        if (index + 1 == this.selectedContact.length) {
          await this.fetchContacts();
          this.$notify.success({
            title: "Success",
            message: "Contacts deleted successfully",
          });
          this.loading = false;
        }
      });
    },
    getContactTypeIdFromValue(value) {
      let Id = "";
      try {
        if (this.getAllContactTypes && this.getAllContactTypes.data) {
          this.getAllContactTypes.data.forEach((type) => {
            if (type.name == value) {
              Id = type._id;
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
      if (Id == "") {
        try {
          if (this.getAllContactTypes && this.getAllContactTypes.data) {
            this.getAllContactTypes.data.forEach((type) => {
              if (type.name == value) {
                Id = type._id;
              }
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
      return Id;
    },
    updatedDateSort: function (a, b) {
      if (a.updated_at < b.updated_at) return -1;
      if (a.updated_at > b.updated_at) return 1;
      return 0;
    },
    checkNameValidation(val) {
      if (val.match(/^\d/)) {
        return false;
      }
      return true;
    },
    async separateSuccessAndErrors() {
      this.uploadedCSVDataResult.forEach((row, index) => {
        let flag = true;
        let existEmail = false;
        var keys = Object.keys(row);
        keys.forEach((key) => {
          if (key == "first_name" || key == "last_name") {
            if (!row[key] || (row[key] && row[key].length < 1)) {
              flag = false;
            }
            if (!this.checkNameValidation(row[key])) {
              flag = false;
            }
          } else if (key == "email") {
            for (
              let i = index + 1;
              i < this.uploadedCSVDataResult.length;
              i++
            ) {
              if (
                this.uploadedCSVDataResult[i] &&
                row[key] &&
                this.uploadedCSVDataResult[i]["email"] == row[key]
              ) {
                existEmail = true;
              }
            }
            if (
              existEmail ||
              !row[key] ||
              (row[key] &&
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
                  row[key]
                ))
            ) {
              flag = false;
            }
          }
        });
        if (flag) {
          this.successfullCSVData.push(row);
        } else if (!existEmail) {
          this.errorfullCSVData.push(row);
        }
      });

      if (this.errorfullCSVData && this.errorfullCSVData.length) {
        this.rearrangeTheErrorCSVData();
      }
      this.successfullCSVData.map((row, index) => {
        row["id"] = index;
      });
      this.totalForIC = this.successfullCSVData.length;
      this.totalForICError = this.errorfullCSVData.length;
      // console.log(
      //   ">>> separateSuccessAndErrors(), successfullCSVData, errorfullCSVData, primaryHeaders",
      //   this.successfullCSVData,
      //   this.errorfullCSVData,
      //   this.primaryHeaders
      // );
    },
    async divideString(e) {
      let dividedString = e.split(/\r?\n/);
      if (dividedString && dividedString.length <= 1) {
        this.$message.error("Oops, Uploaded CSV file is empty.");
      } else {
        const headersTmp = dividedString[0].split(",");
        // console.log(">>> divideString(), headersTmp", headersTmp);

        let headers = [];
        let headersPos = [];
        headersTmp.forEach((item, index) => {
          let itemTmp = item.trim().toLowerCase();
          if (
            itemTmp == "name" ||
            itemTmp == "full name" ||
            itemTmp == "fullname"
          ) {
            headers.push("name");
            headersPos.push(index);
          } else if (
            itemTmp == "first_name" ||
            itemTmp == "first name" ||
            itemTmp == "firstname"
          ) {
            headers.push("first_name");
            headersPos.push(index);
          } else if (
            itemTmp == "last_name" ||
            itemTmp == "last name" ||
            itemTmp == "lastname"
          ) {
            headers.push("last_name");
            headersPos.push(index);
          } else if (
            itemTmp == "email" ||
            itemTmp == "email address" ||
            itemTmp == "email_address" ||
            itemTmp == "emailaddress"
          ) {
            headers.push("email");
            headersPos.push(index);
          } else if (
            itemTmp == "phone_number" ||
            itemTmp == "phone number" ||
            itemTmp == "phonenumber" ||
            itemTmp == "phone" ||
            itemTmp == "cell"
          ) {
            headers.push("phone_number");
            headersPos.push(index);
          } else if (
            itemTmp == "address" ||
            itemTmp == "home address" ||
            itemTmp == "home_address" ||
            itemTmp == "office address" ||
            itemTmp == "office_address"
          ) {
            headers.push("address");
            headersPos.push(index);
          } else if (
            itemTmp == "company_name" ||
            itemTmp == "company name" ||
            itemTmp == "company_name" ||
            itemTmp == "company"
          ) {
            headers.push("company_name");
            headersPos.push(index);
          } else if (itemTmp == "title" || itemTmp == "position") {
            headers.push("title");
            headersPos.push(index);
          }
        });

        // console.log(
        //   ">>> divideString(), headers, headersPos",
        //   headers,
        //   headersPos
        // );

        let differentFields = this.userPrimaryDetailsForSS.filter(
          (x) => headers.indexOf(x) === -1
        );
        // console.log(
        //   ">>> divideString(), userPrimaryDetailsForSS",
        //   this.userPrimaryDetailsForSS
        // );
        // console.log(">>> divideString(), e", e);
        // console.log(">>> divideString(), dividedString", dividedString);
        // console.log(">>> divideString(), differentFields", differentFields);
        if (
          differentFields &&
          differentFields.length &&
          differentFields.length >= this.userPrimaryDetailsForSS.length
        ) {
          this.$message.error(
            "Oops, Uploaded CSV file has no matched columns with required CSV."
          );
        } else {
          this.isCSVUploaded = true;
          this.uploadedCSVDataResult = [];
          for (let i = 1; i < dividedString.length; i++) {
            let obj = {};
            let str = dividedString[i];
            if (dividedString[i] && dividedString[i].length) {
              let properties = str.split(",");
              // console.log(">>> divideString(), properties", properties);
              this.userPrimaryDetailsForSS.forEach((h) => {
                obj[h] = "";
              });
              for (let k in headers) {
                // console.log(
                //   ">>> divideString(), k, headersPos[k]",
                //   k,
                //   headersPos[k]
                // );
                // console.log(
                //   ">>> divideString(), properties[headersPos[k]]",
                //   properties[headersPos[k]]
                // );
                if (properties[headersPos[k]] != "") {
                  const j = headersPos[k];
                  if (properties[j] && properties[j].includes(",")) {
                    obj[headers[k]] = properties[j]
                      .split(",")
                      .map((item) => item.trim());
                  } else {
                    obj[headers[k]] = properties[j];
                  }
                  // console.log(
                  //   ">>> divideString(), obj, k, headers[k], headersPos[k], properties[j]",
                  //   obj,
                  //   k,
                  //   headers[k],
                  //   headersPos[k],
                  //   properties[j]
                  // );
                }
              }

              var isEmpty = true;
              this.userPrimaryDetailsForSS.forEach((h) => {
                if (obj[h].trim().length > 0) {
                  isEmpty = false;
                }
              });
              if (!isEmpty) {
                // console.log(">>> ======== divideString(), obj", obj);
                if (this.filter_contact_type == "") {
                  obj.contact_type = "Receiver";
                } else {
                  obj.contact_type = this.filter_contact_type;
                }
                if ("name" in obj) {
                  var tmpName = obj.name;
                  var words = tmpName.split(" ");
                  if (words.length === 1) {
                    obj.first_name = words[0];
                  } else if (words.length > 1) {
                    obj.last_name = words[words.length - 1];
                    const pos = tmpName.lastIndexOf(obj.last_name);
                    if (pos >= 0) {
                      obj.first_name = tmpName.substring(0, pos);
                    }
                  }
                  delete obj.name;
                }
                this.uploadedCSVDataResult.push(obj);
              }
            }
          }
        }

        this.primaryHeaders.push(
          "contact_type",
          "first_name",
          "last_name",
          "email",
          "phone_number",
          "address",
          "company_name",
          "title"
        );

        this.separateSuccessAndErrors();
      }
      // let result = [];
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchContacts();
      // console.log(a);
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchContacts();
      }
      // console.log(a);
    },
    uploadBulkSendCSV(file) {
      // console.log(">>> uploadBulkSendCSV() called");
      if (
        file.raw.type == "application/vnd.ms-excel" ||
        file.raw.type == "text/csv"
      ) {
        this.uploadedCSVFile = file.raw.name;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.resetCSVData();
          this.activeStep = 1;
          this.divideString(e.target.result);
        };
        reader.readAsText(file.raw);
      } else {
        this.$message.error("Oops, Please upload a .CSV file.");
      }
    },
    generateCSVFile() {
      let primaryHeads = [];
      primaryHeads.push(
        "first_name",
        "last_name",
        "middle_name",
        "email",
        "phone_number",
        "address",
        "company_name",
        "title"
      );

      if (!this.isGenerateCSVFile) {
        this.downloadCSVFile(primaryHeads.join(","));
      }
      this.isGenerateCSVFile = false;
    },
    downloadCSVFile(data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", `importFromSpreadsheet.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleSizeChangeForIC(val) {
      // console.log(`${val} items per page`);
      this.pageSizeForIC = val;
    },
     handleSizeChangeForICError(val) {
      // console.log(`${val} items per page`);
      this.pageSizeForICError = val;
    },
    handleCurrentChangeForIC(val) {
      this.currentPageForIC = val;
    },
     handleCurrentChangeForICError(val){
      this.currentPageForICError = val;
    },
    handleScroll() {
      // console.log(">>> handleScroll() called");
      let scrollbarEl = this.$refs.import_contacts_el_scroll_body;
      // console.log(">>> handleScroll() called, scrollbarEl", scrollbarEl);
      scrollbarEl.onscroll = () => {
        if (scrollbarEl.scrollTop > 135) {
          this.isFixedTop = true;
        } else {
          this.isFixedTop = false;
        }
      };
    },
    async onChangeFilterContactType() {
      await this.fetchContacts();
      if (this.filter_contact_type == "") {
        this.data = this.getAllContacts.data;
        // this.total = this.filtered_data.length;
      }
      if (this.search_string == "") {
        this.data = this.getAllContacts.data;
      } else {
        this.data = this.getAllContacts.data.filter(
          (element) =>
            element.contact_type.name == this.filter_contact_type ||
            element.contact_type.name == this.search_string
        );
        // this.total = this.filtered_data.length;
      }
      this.fetchContacts();
      // console.log("this.", this.search_string);
      // console.log(
      //   ">>> onChangeFilterContactType(), data, this.getAllContacts.data",
      //   this.data,
      //   this.getAllContacts.data
      // );
    },
    async onSearchContactType() {
      await this.fetchContacts();
      if (this.search_string == "") {
        this.data = this.getAllContacts.data;
        // this.total = this.filtered_data.length;
      } else {
        this.data = this.getAllContacts.data.filter(
          (element) => element.contact_type.name == this.search_string
        );
        // this.total = this.filtered_data.length;
      }
      // console.log("this.", this.search_string);
      // console.log(
      //   ">>> onChangeFilterContactType(), data, this.getAllContacts.data",
      //   this.data,
      //   this.getAllContacts.data
      // );
    },

    async addContact(aContact) {
      const contactFormData = {};

      contactFormData.first_name = aContact.first_name;
      contactFormData.last_name = aContact.last_name;
      contactFormData.email = aContact.email;
      contactFormData.phone_number = aContact.phone_number;
      contactFormData.address = aContact.address;
      contactFormData.first_name = aContact.first_name;
      contactFormData.company_name = aContact.company_name;
      contactFormData.title = aContact.title;
      contactFormData.contact_type = aContact.contact_type;
      if (this.getActiveWorkspace && this.getActiveWorkspace.company_id) {
        contactFormData.contact_id = this.getActiveWorkspace.company_id;
      }

      let resultStr = "";
      let userName = "";

      try {
        const _id = this.getContactIdFromEmail(contactFormData.email);
        if (_id === "") {
          // not exist, so add
          await axios.post("/contacts", contactFormData);
        } else {
          // existing contact, so update
          try {
            await axios.patch(`/contacts/${_id}`, contactFormData);
            this.importUpdatedContactsCnt++;
            // eslint-disable-next-line no-empty
          } catch (err02) {}
        }
      } catch (err) {
        if (
          err.response.data.success === false &&
          err.response.data.status_code === 409
        ) {
          // Contact with this email is already taken
          const _id = this.getContactIdFromEmail(contactFormData.email);
          // console.log('>>> Allcontacts', this.allContacts);
          // console.log('>>> _id, email', _id, contactFormData.email, contactFormData);
          try {
            await axios.patch(`/contacts/${_id}`, contactFormData);
            this.importUpdatedContactsCnt++;
            // eslint-disable-next-line no-empty
          } catch (err02) {}
        } else {
          // failed
          resultStr =
            (userName === "" ? "" : userName + ": ") +
            "failed to add, unknown reason";
        }
      }
      return resultStr;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async importContacts() {
      this.showImportContactsPopup = false;
      this.importFromSSActionModal = false;
      this.loadingText = "Importing Contacts...";
      this.loading = true;
      // console.log(">>> importContacts #1");

      // await this.fetchAllContacts();
      // const totalOld = this.total;

      this.importTotalContactsCnt = this.successfullCSVData.length;
      this.importAddedContactsCnt = 0;
      this.importUpdatedContactsCnt = 0;
      this.importFailedContactsCnt = 0;
      this.importErrorDetails = [];

      const batchCnt = 50;
      var cntDone = 0;
      this.importAddedContactsCnt = 0;
      this.importAddedContactsCnt = 0;

      for (
        let k = 0;
        k < Math.floor(this.successfullCSVData.length / batchCnt) + 1;
        k++
      ) {
        // console.log('>>> importContacts(), k, this.successfullCSVData.length / batchCnt + 1', k, this.successfullCSVData.length / batchCnt + 1);
        // for (let i = 0; i < this.successfullCSVData.length; i++) {
        // while (cntDone < k*batchCnt) {
        //   await this.sleep(50);
        //   console.log('>>> importContacts(), sleeping..., cntDone, k*batchCnt', cntDone, k*batchCnt);
        // }
        let contactsData = [];
        for (let i = 0; i < batchCnt; i++) {
          if (k * batchCnt + i >= this.successfullCSVData.length) {
            break;
          }
          var aContact = this.successfullCSVData[k * batchCnt + i];
          // for (let i = 0; i < this.successfullCSVData.length; i++) {
          // for (let i = 0; i < 10; i++) {
          const contactFormData = {};
          contactFormData.first_name = aContact.first_name;
          contactFormData.last_name = aContact.last_name;
          contactFormData.email = aContact.email;
          contactFormData.phone_number = aContact.phone_number;
          contactFormData.address = aContact.address;
          contactFormData.first_name = aContact.first_name;
          contactFormData.company_name = aContact.company_name;
          contactFormData.title = aContact.title;
          contactFormData.account_data_id = this.accountName;
          contactFormData.contact_type = this.entityDataId.name;
          contactFormData.contactType = this.entityDataId.entity_type;
          if (this.getActiveWorkspace && this.getActiveWorkspace.company_id) {
            contactFormData.contact_id = this.getActiveWorkspace.company_id;
          }
          contactsData.push(contactFormData);
        }
         console.log("contactFormData", contactsData);
        var resp = await axios.post("/contacts-bulk", contactsData);
        // console.log('>>> importContacts(), resp', resp.data);
        this.importAddedContactsCnt += resp.data.cntAdded;
        this.importUpdatedContactsCnt += resp.data.cntUpdated;
        cntDone += contactsData.length;
        this.loadingText =
          "Importing Contacts " +
          "(" +
          cntDone +
          "/" +
          this.successfullCSVData.length +
          ")" +
          " ...";
      }
      // self.fetchContacts();
      this.loading = false;
      this.importFailedContactsCnt =
        this.importTotalContactsCnt -
        this.importAddedContactsCnt -
        this.importUpdatedContactsCnt;
      this.viewImportingContactsResultPopup = true;
      this.fetchContacts();
      this.$forceUpdate();
    },

    async importContacts_Old() {
      this.showImportContactsPopup = false;
      this.importFromSSActionModal = false;
      this.loadingText = "Importing Contacts...";
      this.loading = true;
      // console.log(">>> importContacts #1");

      // await this.fetchAllContacts();
      const totalOld = this.total;

      this.importTotalContactsCnt = this.successfullCSVData.length;
      this.importAddedContactsCnt = 0;
      this.importUpdatedContactsCnt = 0;
      this.importFailedContactsCnt = 0;
      this.importErrorDetails = [];

      // console.log(">>> importContacts #2");
      var cntDone = 0;
      var cntToDo = this.successfullCSVData.length;
      var self = this;
      this.loading = true;
      self.loadingText =
        "Importing Contacts " + "(" + cntDone + "/" + cntToDo + ")" + " ...";
      // console.log(">>> addContact(), self.loadingText", self.loadingText);
      const batchCnt = 100;
      for (let k = 0; k < this.successfullCSVData.length / batchCnt + 1; k++) {
        // for (let i = 0; i < this.successfullCSVData.length; i++) {
        while (cntDone < k * batchCnt) {
          await this.sleep(50);
          // console.log(
          //   ">>> importContacts(), sleeping..., cntDone, k*batchCnt",
          //   cntDone,
          //   k * batchCnt
          // );
        }
        for (let i = 0; i < batchCnt; i++) {
          var person = this.successfullCSVData[k * batchCnt + i];
          // const resultStr = await this.addContact(person);
          // if (resultStr !== "") {
          //   this.importErrorDetails.push(resultStr);
          // }
          try {
            this.addContact(person)
              .then((resultStr) => {
                if (resultStr !== "") {
                  self.importErrorDetails.push(resultStr);
                }
                cntDone++;
                self.loadingText =
                  "Importing Contacts " +
                  "(" +
                  cntDone +
                  "/" +
                  cntToDo +
                  ")" +
                  " ...";
                // console.log(
                //   ">>> addContact(), self.loadingText",
                //   self.loadingText
                // );
                if (cntDone >= cntToDo) {
                  self.loading = false;

                  // await self.fetchContacts();
                  self.fetchContacts();

                  self.importAddedContactsCnt = self.total - totalOld;
                  self.importFailedContactsCnt =
                    self.importTotalContactsCnt -
                    self.importAddedContactsCnt -
                    self.importUpdatedContactsCnt;
                  self.viewImportingContactsResultPopup = true;
                }
              })
              .catch(() => {
                // self.loadingText = "Importing Contacts " + "(" + cntDone + "/" + cntToDo + ")" + " ...";
                // console.log('>>> addContact(), > catch, self.loadingText', self.loadingText);
                // if (cntDone >= cntToDo) {
                //   self.loading = false;
                //   // await self.fetchContacts();
                //   self.fetchContacts();
                //   self.importAddedContactsCnt = self.total - totalOld;
                //   self.importFailedContactsCnt =
                //     self.importTotalContactsCnt -
                //     self.importAddedContactsCnt -
                //     self.importUpdatedContactsCnt;
                //   self.viewImportingContactsResultPopup = true;
                // }
              });
          } catch (err) {
            // self.loadingText = "Importing Contacts " + "(" + cntDone + "/" + cntToDo + ")" + " ...";
            // console.log('>>> addContact(), > try catch, self.loadingText', self.loadingText);
            // if (cntDone >= cntToDo) {
            //   self.loading = false;
            //   // await self.fetchContacts();
            //   self.fetchContacts();
            //   self.importAddedContactsCnt = self.total - totalOld;
            //   self.importFailedContactsCnt =
            //     self.importTotalContactsCnt -
            //     self.importAddedContactsCnt -
            //     self.importUpdatedContactsCnt;
            //   self.viewImportingContactsResultPopup = true;
            // }
          }
        }
      }
      // this.loading = false;

      // await this.fetchContacts();

      // this.importAddedContactsCnt = this.total - totalOld;
      // this.importFailedContactsCnt =
      //   this.importTotalContactsCnt -
      //   this.importAddedContactsCnt -
      //   this.importUpdatedContactsCnt;
      // this.viewImportingContactsResultPopup = true;
    },
    editSuccessCSVData(row) {
      this.currentActiveSuccessRowIndex = row.id;
    },
    resetSuccessCSVData(row, column) {
      if (column && column.label == "contact_type") {
        return;
      }
      let checkError = this.checkErrorCountOnSuccessTab();
      if (!checkError) {
        this.successfullCSVData.splice(this.currentActiveSuccessRowIndex, 1);
        this.rearrangeTheErrorCSVData();
      }
      this.currentActiveSuccessRowIndex = -1;
      // this.errorfullCSVData.splice(this.currentActiveRowIndex, 1);

      this.totalForIC = this.successfullCSVData.length;
      this.totalForICError = this.errorfullCSVData.length;
    },
    editErrorCSVData(row) {
      this.currentActiveRowIndex = row.id;
    },
    /*async fetchContacts(){
      try{
        this.configureLoading = true
      }catch(err){
        this.configureLoading = false
      }
    },*/
    resetErrorCSVData(row, column) {
      if (column && column.label == "contact_type") {
        return;
      }
      let checkError = this.checkErrorCount();
      if (checkError) {
        this.errorfullCSVData.splice(this.currentActiveRowIndex, 1);
        this.rearrangeTheErrorCSVData();
      }
      this.currentActiveRowIndex = -1;
      // this.errorfullCSVData.splice(this.currentActiveRowIndex, 1);

      this.totalForIC = this.successfullCSVData.length;
      this.totalForICError = this.errorfullCSVData.length;
    },
    rearrangeTheErrorCSVData() {
      this.errorfullCSVData.map((row, index) => {
        row["id"] = index;
      });
      this.successfullCSVData.map((row, index) => {
        row["id"] = index;
      });
    },
    checkErrorCountOnSuccessTab() {
      var keys = Object.keys(
        this.successfullCSVData[this.currentActiveSuccessRowIndex]
      );
      let flag = true;
      keys.forEach((key) => {
        // console.log('>>> checkErrorCount(), key, this.successfullCSVData[this.currentActiveSuccessRowIndex][key]', key, this.successfullCSVData[this.currentActiveSuccessRowIndex][key]);
        if (key == "first_name" || key == "last_name") {
          if (
            !this.successfullCSVData[this.currentActiveSuccessRowIndex][key] ||
            (this.successfullCSVData[this.currentActiveSuccessRowIndex][key] &&
              this.successfullCSVData[this.currentActiveSuccessRowIndex][key]
                .length < 1)
          ) {
            flag = false;
          }
          if (
            !this.checkNameValidation(
              this.successfullCSVData[this.currentActiveSuccessRowIndex][key]
            )
          ) {
            flag = false;
          }
        } else if (key == "email") {
          if (
            !this.successfullCSVData[this.currentActiveSuccessRowIndex][key] ||
            (this.successfullCSVData[this.currentActiveSuccessRowIndex][key] &&
              (!this.fetchContactEmails(
                this.successfullCSVData[this.currentActiveSuccessRowIndex][key]
              ) ||
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
                  this.successfullCSVData[this.currentActiveSuccessRowIndex][
                    key
                  ]
                )))
          ) {
            flag = false;
          }
          if (this.successfullCSVData[this.currentActiveSuccessRowIndex][key]) {
            // console.log(
            //   ">>>",
            //   flag,
            //   this.fetchContactEmails(
            //     this.successfullCSVData[this.currentActiveSuccessRowIndex][key]
            //   )
            // );
          }
        } else if (key == "phone_number") {
          // console.log('>>> checkErrorCountOnSuccessTab(), this.successfullCSVData', this.successfullCSVData);

          if (
            // !this.successfullCSVData[this.currentActiveSuccessRowIndex][key] ||
            this.successfullCSVData[this.currentActiveSuccessRowIndex][key] &&
            !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
              this.successfullCSVData[this.currentActiveSuccessRowIndex][key]
            )
          ) {
            flag = false;
            // console.log(">>> checkErrorCountOnSuccessTab(), phone #2");
          }
        }
        // } else if (
        //   key == "address" ||
        //   key == "company"
        // ) {
        //   if (!this.successfullCSVData[this.currentActiveSuccessRowIndex][key]) {
        //     flag = false;
        //   }
        // }
      });
      if (!flag) {
        this.errorfullCSVData.push(
          this.successfullCSVData[this.currentActiveSuccessRowIndex]
        );
      }
      // console.log(
      //   ">>> checkErrorCount(), flag, this.errorfullCSVData[this.currentActiveSuccessRowIndex]",
      //   flag,
      //   this.successfullCSVData[this.currentActiveSuccessRowIndex]
      // );

      return flag;
    },
    checkErrorCount() {
      var keys = Object.keys(this.errorfullCSVData[this.currentActiveRowIndex]);
      let flag = true;
      keys.forEach((key) => {
        // console.log('>>> checkErrorCount(), key, this.errorfullCSVData[this.currentActiveRowIndex][key]', key, this.errorfullCSVData[this.currentActiveRowIndex][key]);
        if (key == "first_name" || key == "last_name") {
          if (
            !this.errorfullCSVData[this.currentActiveRowIndex][key] ||
            (this.errorfullCSVData[this.currentActiveRowIndex][key] &&
              this.errorfullCSVData[this.currentActiveRowIndex][key].length < 3)
          ) {
            flag = false;
          }
          if (
            !this.checkNameValidation(
              this.errorfullCSVData[this.currentActiveRowIndex][key]
            )
          ) {
            flag = false;
          }
        } else if (key == "email") {
          if (
            !this.errorfullCSVData[this.currentActiveRowIndex][key] ||
            (this.errorfullCSVData[this.currentActiveRowIndex][key] &&
              (this.fetchErrorContactEmails(
                this.errorfullCSVData[this.currentActiveRowIndex][key]
              ) ||
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
                  this.errorfullCSVData[this.currentActiveRowIndex][key]
                )))
          ) {
            flag = false;
          }
          if (this.errorfullCSVData[this.currentActiveRowIndex][key]) {
            // console.log(
            //   ">>>",
            //   flag,
            //   this.fetchErrorContactEmails(
            //     this.errorfullCSVData[this.currentActiveRowIndex][key]
            //   )
            // );
          }
          // console.log('>>> checkErrorCount(), ### flag, this.errorfullCSVData[this.currentActiveRowIndex][key]', flag, this.errorfullCSVData[this.currentActiveRowIndex][key]);
        } else if (key == "phone_number") {
          if (
            // !this.errorfullCSVData[this.currentActiveRowIndex][key] ||
            this.errorfullCSVData[this.currentActiveRowIndex][key] &&
            !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
              this.errorfullCSVData[this.currentActiveRowIndex][key]
            )
          ) {
            flag = false;
          }
        }
        // } else if (
        //   key == "address" ||
        //   key == "company"
        // ) {
        //   if (!this.errorfullCSVData[this.currentActiveRowIndex][key]) {
        //     flag = false;
        //   }
        // }
      });
      if (flag) {
        this.successfullCSVData.push(
          this.errorfullCSVData[this.currentActiveRowIndex]
        );
      }
      // console.log(
      //   ">>> checkErrorCount(), flag, this.errorfullCSVData[this.currentActiveRowIndex]",
      //   flag,
      //   this.errorfullCSVData[this.currentActiveRowIndex]
      // );

      return flag;
    },
    reuploadCSV() {
      // console.log(">>> reuploadCSV() called");
      if (!this.isBulkSendIsProccessing) {
        this.resetCSVData();
        this.selectedCofigurable = null;
        this.selectedCompanyDocument = null;
        this.activeHeadingNames = [];
        this.getBulkSendPercentage = 0;
        this.isContactsCreated = false;
        this.contactLoading = false;
        // this.importFromSSActionModal = false;
        this.isGenerateCSVFile = false;
        this.primaryHeaders = [];
        // this.currentActiveSuccessRowIndex = -1;
        // this.currentActiveRowIndex = -1;
        this.uploadedCSVFile = "";
        this.docSettingsVisible = false;
      }
    },
    handleClosePopup() {
      this.refreshDocument();
    },
    resetBulkModal() {
      // console.log(">>> resetBulkModal() called");
      if (!this.isBulkSendIsProccessing) {
        this.resetCSVData();
        this.selectedCofigurable = null;
        this.selectedCompanyDocument = null;
        this.activeHeadingNames = [];
        this.getBulkSendPercentage = 0;
        this.isContactsCreated = false;
        this.contactLoading = false;
        this.importFromSSActionModal = false;
        this.showImportContactsPopup = false;
        this.isGenerateCSVFile = false;
        this.primaryHeaders = [];
        this.currentActiveSuccessRowIndex = -1;
        this.currentActiveRowIndex = -1;
        this.uploadedCSVFile = "";
        this.docSettingsVisible = false;
      }
    },
    resetCSVData() {
      this.successfullCSVData = [];
      this.errorfullCSVData = [];
      this.uploadedCSVDataResult = [];
      this.totalForIC = 0;
      this.totalForICError = 0;
      this.activeStep = 0;
    },
    format(percentage) {
      let per = percentage === 100 ? "" : `` || percentage != 100 ? "" : ``;
      console.log(per);
      let doc = "";
      return doc;
    },

    async separateSuccessAndErrorsFromGmail(connections) {
      // console.log(
      //   ">>> separateSuccessAndErrorsFromGmail() called, connections",
      //   connections
      // );
      this.primaryHeaders = [];
      this.primaryHeaders.push("contact_type");
      this.primaryHeaders.push("first_name");
      this.primaryHeaders.push("last_name");
      this.primaryHeaders.push("email");
      this.primaryHeaders.push("phone_number");
      this.primaryHeaders.push("address");
      this.primaryHeaders.push("company_name");
      this.primaryHeaders.push("title");

      this.uploadedCSVDataResult = [];
      this.successfullCSVData = [];
      this.errorfullCSVData = [];

      for (let i = 0; i < connections.length; i++) {
        var aContact = connections[i];
        var aContactForRow = {};

        try {
          if (this.filter_contact_type == "") {
            aContactForRow.contact_type = "Receiver";
          } else {
            aContactForRow.contact_type = this.filter_contact_type;
          }

          if (aContact.names && aContact.names.length > 0) {
            // first_name
            if (aContact.names[0].givenName) {
              aContactForRow.first_name = aContact.names[0].givenName;
            } else {
              aContactForRow.first_name = aContact.names[0].displayName;
            }

            // last_name
            if (aContact.names[0].familyName) {
              aContactForRow.last_name = aContact.names[0].familyName;
            } else {
              aContactForRow.last_name = "";
            }
          }

          // email
          if (aContact.emailAddresses && aContact.emailAddresses.length > 0) {
            if (aContact.emailAddresses[0].value) {
              aContactForRow.email = aContact.emailAddresses[0].value;
            }
          } else {
            aContactForRow.email = "";
          }

          // phone_number
          if (aContact.phoneNumbers && aContact.phoneNumbers.length > 0) {
            if (aContact.phoneNumbers[0].value) {
              aContactForRow.phone_number = aContact.phoneNumbers[0].value;
              // contactFormData.phone_number = '';
            }
          } else {
            aContactForRow.phone_number = "";
          }

          // address
          if (aContact.addresses && aContact.addresses.length > 0) {
            try {
              aContactForRow.address = this.buildCommaSeparatedString([
                aContact.addresses[0].streetAddress,
                aContact.addresses[0].city,
                aContact.addresses[0].region,
                aContact.addresses[0].country,
                aContact.addresses[0].postalCode,
              ]);
            } catch (e) {
              aContactForRow.address = "";
            }
          } else {
            aContactForRow.address = "";
          }

          // company_name
          aContactForRow.company_name = "";
          aContactForRow.title = "";
          if (aContact.organizations && aContact.organizations.length > 0) {
            if (aContact.organizations[0].name) {
              aContactForRow.company_name = aContact.organizations[0].name;
            }
            if (aContact.organizations[0].title) {
              aContactForRow.title = aContact.organizations[0].title;
            }
          }
          this.uploadedCSVDataResult.push(aContactForRow);
        } catch (err) {
          // console.log(
          //   ">>> separateSuccessAndErrorsFromGmail() parsing error.",
          //   err
          // );
        }
      }
      this.separateSuccessAndErrors();
    },

    async fetchAllContacts() {
      try {
        // this.loading = true;
        let params = { get_all: true };
        // fetchAllCompanyContacts
        let response = await axios.get("/contacts", { params });
        this.allContacts = response.data.data;
        // this.loading = false;
      } catch (err) {
        // this.loading = false;
      }
    },
    async fetchContacts() {
      try {
        this.loading = true;
        let params = {
          contactType: this.contactType,
          accountName: this.accountName,
          limit: this.pageSize > 0 ? this.pageSize : 5,
          page: this.currentPage,
        };
        let contact_type = "";
        try {
          contact_type = this.getContactTypeIdFromValue(
            this.filter_contact_type
          );
        } catch (err) {
          console.log(err);
        }
        if (contact_type != "") {
          params.contact_type = contact_type;
        }
        try {
          contact_type = this.getContactTypeIdFromValue(this.search_string);
        } catch (err) {
          console.log(err);
        }
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        // await this.$store.dispatch("contacts/fetchAllContacts", params);
        await this.$store.dispatch("contacts/fetchAllContactsByEntity", params);
        // console.log("this.accountName", this.accountName);
        this.contactFormData.account_data_id = this.accountName;
        this.contactFormData.contact_type = this.entityDataId.name;
        this.data = this.getAllContacts.data;
        this.total = this.getAllContacts.total;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async onEdit(contact) {
      this.isEdit = true;
      this.contact_id = contact._id;
      // console.log(contact);
      this.loadingText = "Fetching Contact...";
      await this.$store.dispatch("contacts/fetchContact", contact._id);
      this.setContact();
    },
    getContactTypesFromContact(contact) {
      let result = [];
      if (contact?.contact_types && contact.contact_types.length) {
        result = contact.contact_types.map((e) => {
          if (e?.contact_type?._id) {
            e.contact_type = e.contact_type._id;
          }
          if (e?.account_data_id?._id) {
            e.account_data_id = e.account_data_id._id;
          }
          return e;
        });
      } else if (contact.contact_type) {
        let obj = {};
        obj["contact_type"] = contact.contact_type._id
          ? contact.contact_type._id
          : contact.contact_type;
        obj["has_login"] = true;
        if (contact.account_data_id) {
          obj["account_data_id"] = contact.account_data_id;
        }
        result = [obj];
      }
      return result;
    },
    setContact() {
      if (this.getContact) {
        this.contactFormData.first_name = this.getContact.first_name;
        this.contactFormData.last_name = this.getContact.last_name;
        this.contactFormData.email = this.getContact.email;
        this.contactFormData.phone_number = this.getContact.phone_number;
        this.contactFormData.address = this.getContact.address;
        this.contactFormData.company_name = this.getContact.company_name;
        this.contactFormData.contact_types = this.getContactTypesFromContact(this.getContact);
        this.contactFormData.title = this.getContact.title;
        this.addContactPopUp = true;
      }
    },
    async updateContact() {
      try {
        this.loading = true;
        this.loadingText = "Updating Contact...";
        if (
          this.contactFormData.email.toLowerCase() ==
          this.getAuthenticatedUser.email.toLowerCase()
        ) {
          // this.$message()
          this.$message.warning("oops, You entered a Sender email");
          this.loading = false;
          return;
        }
        let index = this.contactFormData.contact_types.findIndex(e => e.contact_type == this.entityDataId._id);
        if(index == -1){
          this.contactFormData.contact_types.push({
            contact_type: this.entityDataId._id,
            account_data_id: this.accountName,
            has_login: true
          });
        }else{
          this.contactFormData.contact_types[index].account_data_id = this.accountName;
        }
        if(this.contactFormData.contact_type){
          delete this.contactFormData.contact_type
        }
        await this.$store.dispatch("contacts/updateContact", {
          contact_id: this.contact_id,
          contact: this.contactFormData,
        });
        this.loading = false;
        if (this.getUpdateContactStatus) {
          this.$notify.success({
            title: "Success",
            message: "Contact updated successfully",
          });
          // this.goToContactList();
          this.addContactPopUp = false;
          this.isEdit = false;
          this.contact_id = null;
          this.fetchContacts();
        } else if (this.getContactErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error in updating Contact",
          });
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.currentPage = data.page;
        await this.fetchContacts();
      }
    },

    async onDelete(id) {
      this.$confirm("Are you sure to delete the contact?")
        .then(async () => {
          this.contactdeletionloading = true;
          await this.$store.dispatch("contacts/deleteContact", id);
          if (this.getContactDeleteStatus) {
            this.documentActionModal = false;
            await this.fetchContacts();
            this.$notify.success({
              title: "Success",
              message: "Contact deleted successfully",
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error in deleting contact",
            });
          }

          this.conatactdeletionloading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    resetForm() {
      this.contactFormData.first_name = "";
      this.contactFormData.last_name = "";
      this.contactFormData.email = "";
      this.contactFormData.title = "";
      this.contactFormData.phone_number = "";
      this.contactFormData.address = "";
      this.contactFormData.address = "";
      this.contactFormData.company_name = "";
      this.contactFormData.contact_type = "";
      this.contactFormData.account_data_id = "";
    },
    goToCreateContact() {
      this.modalLoading = true;
      this.isEdit = false;
      this.contact_id = null;
      this.resetForm();
      // this.$router.push({ name: "add-contact" });
      this.addContactPopUp = true;
      // setTimeout(() => {
      this.modalLoading = false;
      // }, 1500);
    },
    goToCreateContactType() {
      this.$router.push({ name: "contactTypes" });
    },
    goToContactList() {
      this.$router.push({
        path: "/contacts",
      });
    },
    goToDocuments(contact) {
      this.$router.push({
        name: "user-contacts",
        params: {
          contact_id: contact.email,
          name: contact.first_name,
          lastname: contact.last_name,
        },
      });
    },
    async goToImportGmailContact() {
      try {
        await this.$google.api.auth2.getAuthInstance().signIn();
        this.listConnectionNames();
      } catch (e) {
        console.log(e);
      }
    },
    async goToImportSpreadsheetContact() {
      this.resetCSVData();
      this.importFromSSActionModal = true;

      document.body.className += " modal-open";
    },
    async goToImportOutlookContact() {
      let contacts = await this.client
        .api("/me/contactFolders/{id}/contacts")
        .get();

      console.log(">>> contacts: ", contacts);
    },
    buildCommaSeparatedString(arr) {
      let rtn = "";
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== undefined && arr[i].trim().length > 0) {
          if (rtn.length > 0) {
            rtn += ", ";
          }
          rtn += arr[i].trim();
        }
      }
      return rtn;
    },

    getContactIdFromEmail(email) {
      for (let i = 0; i < this.allContacts.length; i++) {
        if (
          this.allContacts[i].email.toLowerCase().indexOf(email.toLowerCase()) >
          -1
        ) {
          return this.allContacts[i]._id;
        }
      }
      return "";
    },
    async listConnectionNames() {
      const self = this;
      this.$google.api.client.people.people.connections
        .list({
          resourceName: "people/me",
          // 'pageSize': 10,
          // 'personFields': 'names,emailAddresses',
          personFields:
            "addresses,ageRanges,biographies,birthdays,calendarUrls,clientData,coverPhotos,emailAddresses,events,externalIds,genders,imClients,interests,locales,locations,memberships,metadata,miscKeywords,names,nicknames,occupations,organizations,phoneNumbers,photos,relations,sipAddresses,skills,urls,userDefined",
        })
        .then(async function (response) {
          var connections = response.result.connections;
          // console.log("Connections:", connections);
          if (connections.length > 0) {
            self.separateSuccessAndErrorsFromGmail(connections);

            self.loading = true;
            self.loadingText = "Loading Contacts...";
            await self.fetchAllContacts();
            self.loading = false;
            self.showImportContactsPopup = true;

            // const totalOld = self.total;
            // self.importTotalContactsCnt = connections.length;
            // self.importAddedContactsCnt = 0;
            // self.importUpdatedContactsCnt = 0;
            // self.importFailedContactsCnt = 0;
            // self.importErrorDetails = [];

            // for (let i = 0; i < connections.length; i++) {
            //   var person = connections[i];
            //   const resultStr = await self.addContact(person);
            //   if (resultStr !== "") {
            //     self.importErrorDetails.push(resultStr);
            //   }
            //   // if (person.names && person.names.length > 0) {
            //   //   const resultStr = await self.addContact(person);
            //   //   if (resultStr !== '') {
            //   //     self.importErrorDetails.push(resultStr);
            //   //   }
            //   // } else {
            //   //     const resultStr = "first name and last name are empty";
            //   //     self.importErrorDetails.push(resultStr);
            //   //   // console.log("No display name found for connection.");
            //   // }
            // }
            // await self.fetchContacts();

            // // self.$notify.success({
            // //   title: "Success",
            // //   message: "Contacts imported, count: " + totalAdded,
            // // });
            // // self.loading = false;
            // self.importAddedContactsCnt = self.total - totalOld;
            // self.importFailedContactsCnt =
            //   self.importTotalContactsCnt -
            //   self.importAddedContactsCnt -
            //   self.importUpdatedContactsCnt;
            // self.viewImportingContactsResultPopup = true;
          } else {
            // console.log('No contacts imported.');
          }
        });
    },
    checkPermissions(permission) {
      if (this.getUserType && this.getUserType.permissionSet) {
        return this.getUserType.permissionSet.includes(permission)
          ? true
          : false;
      } else {
        return true;
      }
    },
    async addContactInfo() {
      try {
        this.loading = true;
        this.loadingText = "Adding Contact...";
        if (
          this.contactFormData.email.toLowerCase() ==
          this.getAuthenticatedUser.email.toLowerCase()
        ) {
          // this.$message()
          this.$message.warning("oops, You entered a Sender email");
          this.loading = false;
          return;
        }
        if (
          !this.accountName
        ) {
          this.$message.warning("oops, Please save entity data.");
          this.loading = false;
          return;
        }
        this.contactFormData.account_data_id = this.accountName;
        this.contactFormData.contact_types = [
          {
            contact_type: this.entityDataId._id,
            account_data_id: this.accountName,
            has_login: true
          }
        ];
        if(this.contactFormData.contact_type){
          delete this.contactFormData.contact_type
        }
        // this.contactFormData.contactType = this.entityDataId.entity_type;
        // console.log("this.contactFormData", this.contactFormData);

        await this.$store.dispatch(
          "contacts/createContact",
          this.contactFormData
        );
        this.loading = false;
        if (this.getCreateContactStatus) {
          this.$notify.success({
            title: "Success",
            message: "Contact created successfully",
          });
          this.addContactPopUp = false;
          this.fetchContacts();
          this.addContactPopUp = false;
          this.isEdit = false;
          this.contact_id = null;
          // this.goToContactList();
        } else if (this.getContactErrors && this.getContactErrors.length) {
          this.$notify.error({
            title: "Error",
            message: "Error in creating Contact",
          });
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
  },
  watch: {
    search_string: function (val) {
      this.search_string = val;
      this.fetchContacts();
    },
  },
  // beforeDestroy() {
  //   this.$store.commit("contacts/setAllContacts",null,{root:true})
  //   this.$store.commit("contacts/setContactDeleteStatus",null,{root:true})
  //   this.$store.commit("contacts/setCreateContactStatus",null,{root:true})
  //   this.$store.commit("contacts/setContactErrors",null,{root:true})
  //   this.$store.commit("contacts/setUpdateContactStatus",null,{root:true})
  //   this.$store.commit("contacts/setContact",null,{root:true})
  //   this.$store.commit("contactTypes/setAllContactTypes",null,{root:true})
  // }
};
</script>

<style lang="scss">
body.modal-open {
  height: 100vh;
  //overflow-y: hidden;
}
.import-dropdown-btn {
  margin-left: 10px;
  height: 32px !important;
  .el-button {
    height: 100%;
    font-size: 14px;
    span {
      padding: 0 10px;
    }
  }
}
.import-dropdown-btn-menu {
  .el-dropdown-menu__item {
    display: flex;
    img {
      width: 25px;
      height: 25px;
      padding: 3px;
      background: #f7f7f7;
      border-radius: 10px;
    }
    span {
      margin-top: -2px;
      margin-left: 10px;
    }
  }
}
.importing-result-popup {
  .el-dialog__body {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.el-table__empty-text {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.tab-success {
  .el-scrollbar__thumb {
    width: 20px;
  }
}
.tab-error {
  .el-scrollbar__thumb {
    width: 20px;
  }
}
.import-contacts-table {
  min-height: 350px;
}
.import-contacts-table-wrapper {
  display: inline-block;
  .el-table {
    display: inline-block;
    .el-table__body-wrapper {
      display: inline-block !important;
    }
  }
}
.top-search-condition-bar-type-two {
  @media (max-width: 991.98px) {
    display: flex !important;
    flex-direction: column !important;
    .actions-wrapper {
      display: flow-root !important;
      .import-contact {
        padding: 6px !important;
      }
      button {
        margin-top: 5px;
      }
      .filter-contact-type {
        margin-top: 5px;
      }
    }
    h3 {
      width: 100%;
      margin-left: 10px;
    }
  }
  .actions-wrapper {
    .filter-contact-type {
      margin-left: 10px;
      //margin-right: 10px;
      width: 200px;
    }
    .import-contact {
      margin-left: 10px !important;
      padding: 6px 20px;
      height: 32px !important;
      width: 120px;
      // .icon-type-one {
      //   width: 25px;
      //   // height: 25px;
      //   &.gmail {
      //   }
      // }
      // .text {
      //   margin-left: 10px;
      //   margin-right: 23px;
      //   position: relative;
      //   top: -6px;
      // }
    }
  }
}
.indent {
  margin-left: 20px !important;
}
.error-details-title {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
}
.error-details {
  color: red;
  font-style: italic;
}
.result-row {
  margin-left: 10px;
  margin-bottom: 15px;
  .larger-font-size {
    font-size: 15px;
  }
  &.failed {
    color: red;
  }
}
.mrg {
  margin-left: 55%;
  border-radius: 2px;
}
.ntg {
  background: #2f80ed;
  color: #ffffff !important;
  border-radius: 2px;
}
.all-documents-view {
  .title {
    letter-spacing: 0.019em;
  }
  // .icon-block {
  .initial {
    grid-template-columns: 35px auto;
    .icon {
      border: 1px solid #ffa9a9;
      text-align: center;
      border-radius: 30px;
      padding: 5px;
      background-color: #f1494910;
      .initial-text {
        font-size: 0.8em;
        span {
          color: #f14949;
        }
      }
    }
  }
  // }
  body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    // background-color: #F0AD4E;
  }
  label {
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding: 5px 10px;
    transition: all 1s ease;
    border-radius: 6px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    &::after {
      content: "";
      display: block;
      height: 2px;
      width: 80%;
      background-color: #f754a2;
      transition: all 1s ease 0.5s;
    }
    input {
      transition: width 1s ease, opacity 0.5s ease 0.5s;
      //opacity: 0.5;
      width: 180px;
      height: 18px;
      border: 0;
      outline: none;
    }
    i {
      position: absolute;
      top: 11px;
      right: 11px;
      border-color: #333;

      cursor: pointer;
    }
    &[data-state="close"] {
      border-radius: 6px;
      padding: 2px;
      transition: all 0.5s ease;
      &::after {
        width: 0%;
        transition: all 0.2s ease;
      }
      i {
        pointer-events: none;
      }
      input {
        width: 28px;
        height: 25px;
        border-radius: 4px;
        opacity: 0;
        cursor: pointer;
        transition: opacity 0.5s ease, width 1s ease;
        -webkit-appearance: none;
      }
    }
  }
  .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;
    &::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }
    &.completed::after {
      background: #08ad36;
    }
    &.draft::after {
      background: #c4c4c4;
    }
    &.viewed::after {
      background: #2f80ed;
    }
  }
}
.DialogueStyle {
  margin-top: 50px;
}
.top-search-condition-bar-type-two {
  display: flex;
  flex-direction: column;
  h3 {
    margin-bottom: 15px !important;
  }
  .contacts-page {
    &.actions-wrapper {
      justify-content: end;
      .create-btn {
        height: 34px !important;
        margin-left: 10px !important;
      }
      .filter-contact-type {
        margin-left: 10px !important;
      }
      .search-icon-wrapper {
        height: 32px !important;
        padding-top: 6px;
        padding-bottom: 0px;
      }
      @media (max-width: 1166.98px) {
        display: flex;
        flex-direction: column !important;
        .first-line {
          display: flex;
          justify-content: end;
          margin-bottom: 10px !important;
          button {
            margin-top: 0px;
            margin-left: 5px !important;
            padding-left: 5px !important;
            padding-right: 5px !important;
          }
          .search-icon-wrapper {
          }
        }
        .second-line {
          display: flex;
          justify-content: end;
          .filter-contact-type {
            margin-left: 0px !important;
          }
          .import-dropdown-btn {
            margin-left: 5px !important;
            button {
              padding: 5px !important;
            }
          }
          .create-btn {
            padding: 10px !important;
            margin-left: 5px !important;
          }
        }
      }
    }
  }
}
.contacts-list-table {
  .action-buttons {
    display: flex;
    flex-direction: row !important;
    & > li {
      margin-right: 0px !important;
      margin-left: 0px !important;
      & > button {
        margin-right: 3px !important;
        margin-left: 0px !important;
      }
    }
    .first-button {
    }
    .second-button {
    }
    .third-button {
    }
  }
}
.avatar-icon-in-table {
  min-height: 35px !important;
}
</style>
<style lang="scss">
.download-csv-button-wrapper {
  @media (max-width: 400px) {
    flex-direction: column !important;
    align-items: end;
  }
  .download-csv {
    width: 150px;
    height: 35px;
    margin-right: 6px !important;
  }
}
.el-button--mini:hover{
  color:var(--primary-color) !important;
}
</style>