<template>
  <div class="try-using-ai" 
    :style="{ top: top + 'px', left: left + 'px' }"
    @mousedown="startDrag">
    <el-row class="new-box-heading">
      <img src="@/assets/Kodefast.svg" />
      <span class="ai-text">Try Using AI</span>
      <el-tooltip content="Preview" placement="top">
        <i
          class="el-icon-view"
          style="margin-left: auto; font-size: 15px; cursor: pointer"
          @click="showPreviewPopUP"
          v-if="displayData"
        ></i>
      </el-tooltip>
      <span class="divider" v-if="displayData"></span>
      <el-link :underline="false" class="Summarize" @click="showSummarizePopUp">
        <span v-if="displayData">Summarize</span>
      </el-link>
    </el-row>
    <i
      class="el-icon-close closeIcon"
      style="font-size: 15px; cursor: pointer"
      @click="closeTryUsingAI"
    ></i>

    <el-row>
      <div class="displayData">
        <div
          v-html="displayData"
          v-loading="loading"
          element-loading-text="Loading, please wait..."
        ></div>
      </div> 
    </el-row>
    <br />

    <el-row class="flex-container">
      <div class="flex-item">
        <p class="sel-content">Select Operation</p>
        <el-select
          v-model="tryUsingAISettings.userSelectedOperation"
          class="sel-width"
          size="mini"
          clearable
          placeholder="Select Operation"
        >
          <el-option
            v-for="(option, index) in operationOptions"
            :key="index"
            :label="option.label"
            :value="option.value"
          ></el-option>
        </el-select>
      </div>
      <div class="flex-item">
        <p class="sel-content">Input Fields</p>
        <el-select
          v-model="tryUsingAISettings.additonalFields"
          class="sel-width"
          size="mini"
          clearable
          multiple=""
          placeholder="Input Fields"
          collapse-tags=""
        >
          <el-option
            v-for="(option, index) in fieldsData"
            :key="index"
            :label="option.label"
            :value="option.key"
          ></el-option>
        </el-select>
      </div>
      <div class="flex-item">
        <p class="sel-content">Select Output Field</p>
        <el-select
          v-model="tryUsingAISettings.outputField"
          class="sel-width"
          size="mini"
          clearable
          placeholder="Output Fields"
          collapse-tags=""
          :required="true"
        >
          <el-option
            v-for="(option, index) in filteredOutputFields"
            :key="index"
            :label="option.label"
            :value="option.key"
          ></el-option>
        </el-select>
      </div> 
    </el-row>
    <br />

    <el-row>
  <div class="input-with-icon">
    <el-input
      v-model="tryUsingAISettings.manualPrompt"
      type="textarea"
      autosize
      placeholder="Enter Desired Prompt Here"
      class="input-width"
    ></el-input>
    <el-tooltip
      v-if="!isOperationAndFieldsSelected"
      content="Please select an operation and output fields"
      placement="top"
    >
      <img
        :src="iconSrc"
        class="suffix-icon"
        alt="Enter Icon"
        @click="handleIconClick"
        :style="{ cursor: 'not-allowed' }"
        :class="{ 'icon-disabled': !isOperationAndFieldsSelected }"
      />
    </el-tooltip>
      <img
       v-if="isOperationAndFieldsSelected"
        :src="iconSrc"
        class="suffix-icon"
        alt="Enter Icon"
        @click="handleIconClick"
        :style="{ cursor: 'pointer' }"
      />
  </div>
</el-row>

    <div v-if="displayData" class="container">
    <div class="icon-container">
      <el-tooltip content="Previous" placement="top">
        <i class="el-icon-arrow-left" @click="showPreviousResponse"></i>
      </el-tooltip>
      <span>{{ currentResponseIndex + 1 }} / {{ responses.length }}</span>
      <el-tooltip content="Next" placement="top">
        <i class="el-icon-arrow-right" @click="showNextResponse"></i>
      </el-tooltip>
      <el-tooltip content="Copy" placement="top">
        <img src="@/assets/Copy.svg" @click="copyContent" />
      </el-tooltip>
      <el-tooltip content="Append" placement="top">
        <i class="el-icon-plus" @click="appendContent"></i>
      </el-tooltip>
      <el-tooltip content="Regenerate" placement="top">
        <img src="@/assets/Regenerate.svg" @click="regenerateAIForField" />
      </el-tooltip>
      <div class="image-container">
        <img src="@/assets/Sliders.svg" alt="Sliders" />
        <div class="hover-items">
          <div @click="selectCharacteristics('short')">Short</div>
          <div @click="selectCharacteristics('medium')">Medium</div>
          <div @click="selectCharacteristics('elaborate')">Elaborate</div>
        </div>
      </div>
      <div class="image-container">
        <img src="@/assets/emoji-smile.svg" alt="Emoji" />
        <div class="hover-items">
          <div @click="selectTone('formal')">Formal</div>
          <div @click="selectTone('informal')">Informal</div>
          <div @click="selectTone('friendly')">Friendly</div>
          <div @click="selectTone('persuasive')">Persuasive</div>
        </div>
      </div>
    </div>
  </div>
    <dialog-component
      :title="'Preview'"
      :visible="showPreview"
      @before-close="showPreview = false"
      :containerWidth="'40%'"
      :containerMaxHeight="'50%'"
      :containerMinHeight="'20%'"
      :isShowFooter="false"
    >
      <div v-html="displayData" class="m-tb-2"></div>
    </dialog-component>
    <dialog-component
      :title="'Summary'"
      :visible="showSummary"
      @before-close="showSummary = false"
      :containerWidth="'40%'"
      :containerMaxHeight="'60%'"
      :containerMinHeight="'20%'"
      :isShowFooter="false"
      v-loading="loadingSummary"
      element-loading-text="Summarizing the Data..."
    >
      <div v-html="summaryData" class="m-tb-2"></div>
    </dialog-component>
  </div>
</template>
<script>
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper";
import axios from "axios";
export default {
  components: {},
  mixins: [TemplateBuilderHelper],
  props: ["fieldsData", "form", "type", "prompt"],
  data() {
    return {
      iconSrc: require("@/assets/Enter-Active.svg"),
      loading: false,
      operationOptions: [
        { label: "Content Creation", value: "content_generation" },
        { label: "Keyword Extraction", value: "keyword_extraction" },
        { label: "Sentiment Analysis", value: "sentiment_analysis" },
        { label: "Summarization", value: "summary" },
      ],
      tryUsingAISettings: {
        userSelectedOperation: null,
        additonalFields: [],
        outputField: null,
        manualPrompt: "",
      },
      displayData: "",
      responses: [],
      currentResponseIndex: -1,
      showPreview: false,
      showContainer: false,
      showSummary: false,
      summaryData: "",
      loadingSummary: false,
      isDragging: false,
      startX: 0,
      startY: 0,
      top: 70,
      left: window.innerWidth - 500,
    };
  },
  computed: {
    isOperationAndFieldsSelected() {
      return this.tryUsingAISettings.userSelectedOperation && this.tryUsingAISettings.outputField;
    },
    hasManualPrompt() {
    return this.tryUsingAISettings.manualPrompt && this.tryUsingAISettings.manualPrompt.trim() !== '';
  },
    filteredOutputFields() {
    if (this.tryUsingAISettings.userSelectedOperation === "content_generation") {
      return this.fieldsData.filter(field => field.inputType === "MULTI_LINE_TEXT");
    } else if (this.tryUsingAISettings.userSelectedOperation === "keyword_extraction") {
      return this.fieldsData.filter(field => 
        field.inputType === "MULTI_LINE_TEXT" || field.inputType === "MULTI_SELECT"
      );
    } else if (this.tryUsingAISettings.userSelectedOperation === "summary") {
      return this.fieldsData.filter(field => field.inputType === "MULTI_LINE_TEXT");
    } else if (this.tryUsingAISettings.userSelectedOperation === "sentiment_analysis") {
      return this.fieldsData.filter(field => 
        field.inputType === "SINGLE_LINE_TEXT" || field.inputType === "SELECT"
      );
    } else {
      return [];
    }
  },
  },

  methods: {
    startDrag(event) {
      this.isDragging = true;
      this.startX = event.clientX - this.left;
      this.startY = event.clientY - this.top;
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },
    onDrag(event) {
      if (this.isDragging) {
        this.left = event.clientX - this.startX;
        this.top = event.clientY - this.startY;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    },
    showPreviewPopUP() {
      this.showPreview = true;
    },
    handleIconClick() {
    if (!this.tryUsingAISettings.userSelectedOperation) {
      this.$message.info("Please select an operation");
      return;
    }
    if (!this.tryUsingAISettings.outputField) {
      this.$message.info("Please select an output field");
      return;
    }
    if (!this.tryUsingAISettings.additonalFields || this.tryUsingAISettings.additonalFields.length === 0) {
      this.$message.info("Please select Input fields");
      return;
    }
    this.generateAIForField();
  },
    selectCharacteristics(item) {
      this.selectedCharacteristics = item;
      this.generateAIForField();
    },
    selectTone(item) {
      this.selectedTone = item;
      this.generateAIForField();
    },

    toggleContainer() {
      this.showContainer = true;
    },
    closeTryUsingAI() {
      this.$emit("close");
    },
    async generateAIForField() {
  this.loading = true;

  try {
    let outputField = this.fieldsData.find(
      (e) => e.key == this.tryUsingAISettings.outputField 
    );
    const promptMessage = this.getUserPromptMessage(
      this.fieldsData,
      this.form,
      outputField.input_type
    );
    let response = await this.tryUsingAISettingsResponse(
      promptMessage,
      this.tryUsingAISettings.userSelectedOperation,
      false 
    );

    if (typeof response === 'string') {
      if (response.startsWith("```html")) {
        response = response.substring(7);
      }
      if (response.endsWith("```")) {
        response = response.substring(0, response.length - 3);
      }
    }

    this.displayData = response;
    this.responses.push(response);
    this.currentResponseIndex = this.responses.length - 1;
  } catch (error) {
    console.error("Error generating AI for field:", error);
  } finally {
    this.loading = false;
  }
},
copyContent() {
  const outputField = this.fieldsData.find(e => e.key == this.tryUsingAISettings.outputField);

  if (!this.displayData) {
    return this.$message.warning("No data to copy");
  }

  let fieldKey;
  if (this.tryUsingAISettings.outputField.includes("#")) {
    fieldKey = this.tryUsingAISettings.outputField.split('#')[1];
  } else {
    fieldKey = this.tryUsingAISettings.outputField; 
  }

  if (outputField) {
    let dataToCopy;

    if (outputField.input_type === "MULTI_SELECT" && Array.isArray(this.displayData)) {
      dataToCopy = this.displayData;
    } else {
      dataToCopy = Array.isArray(this.displayData) ? this.displayData.join(", ") : this.displayData;
      if (typeof dataToCopy !== "string") {
        dataToCopy = dataToCopy.toString();
      }
    }
    this.$emit("copy-content", { fieldKey, dataToCopy });
  } 
},
    async regenerateAIForField() {
      this.loading = true;
      try {
        let outputField = this.fieldsData.find(
      (e) => e.key == this.tryUsingAISettings.outputField
    );
        const promptMessage = this.getUserPromptMessage(
          this.fieldsData,
          this.form,
          outputField.input_type
        );
        let response = await this.tryUsingAISettingsResponse(
          promptMessage,
          this.tryUsingAISettings.userSelectedOperation,
          true
        );
        if (typeof response === 'string') {
      if (response.startsWith("```html")) {
        response = response.substring(7);
      }
      if (response.endsWith("```")) {
        response = response.substring(0, response.length - 3);
      }
    }
        this.displayData = response;
        this.responses.push(response);
        this.currentResponseIndex = this.responses.length - 1;
      } catch (error) {
        console.error("Error regenerating AI for field:", error);
      } finally {
        this.loading = false;
      }
    },

    showPreviousResponse() {
      if (this.currentResponseIndex > 0) {
        this.currentResponseIndex--;
        this.displayData = this.responses[this.currentResponseIndex];
      }
    },

    showNextResponse() {
      if (this.currentResponseIndex < this.responses.length - 1) {
        this.currentResponseIndex++;
        this.displayData = this.responses[this.currentResponseIndex];
      }
    },
    appendContent() {
      const outputFieldKey = this.tryUsingAISettings.outputField;
      if (outputFieldKey && this.displayData) {
        if (this.form[outputFieldKey]) {
          this.form[outputFieldKey] += `\n\n${this.displayData}`;
        } else {
          this.$set(this.form, outputFieldKey, this.displayData);
        }
      } 
    },
    async showSummarizePopUp() {
      this.loadingSummary = true;
      this.showSummary = true;
      try {
        const response = await this.generateSummaryForField(this.displayData);
        this.summaryData = response;
      } catch (error) {
        console.error("Error generating summary:", error);
      } finally {
        this.loadingSummary = false; 
      }
    },

    async generateSummaryForField(currentResponse) {
      try {
        const session_id = `${this.getActiveWorkspace.company_id}#${this.getAuthenticatedUser._id}`;
        const userPromptMessage = "Please summarize the following data.";
        const fullPrompt = `${userPromptMessage}\n\n${currentResponse}\n\n`;
        const payload = {
          Enter_your_prompt: fullPrompt,
          session_id: session_id,
          prompt_type: "summary",
          flag: true,
        };

        const response = await axios.post(
          "https://ai.esigns.io/ai_features/",
          payload
        );
        return response.data.data; 
      } catch (error) {
        console.error("Error generating summary:", error);
        throw error;
      }
    },
  },
};
</script>


<style scoped>
.try-using-ai {
  position: fixed;
  top: 70px;
  right: 35px;
  width: 450px;
  height: auto;
  max-height: 90vh; 
  background: white;
  border: 1px solid #dcdfe6;
  padding: 20px;
  z-index: 100;
  cursor: move;
}

.new-box-heading {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-right: 30px;
}

.Summarize {
  color: #409eff;
  font-weight: bold;
}

.ai-text {
  color: var(--primary-color);
  font-weight: bold;
}

.new-box-heading img {
  margin-right: 10px;
}

.new-box-heading .el-tooltip {
  margin-left: auto;
}

.divider {
  border-left: 1px solid #dcdfe6;
  height: 20px;
  margin: 0 8px;
  display: inline-block;
  vertical-align: middle;
}

.displayData {
  height: 300px; 
  overflow-y: auto; 
  color: black;
  border: 1px solid #eaeaea;
  padding: 20px;
  background-color: #f8fafc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
  cursor: default;
}

.sel-content {
  font-weight: bold;
  margin-bottom: 5px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.flex-item {
  flex: 1;
  cursor: pointer;
}

.sel-content {
  font-weight: 500;
  margin-bottom: 5px;
}

.sel-width {
  width: 100%;
}

.inherit-font ::v-deep .el-textarea__inner {
  font-family: "Inter";
  font-size: 13px;
}

.input-with-icon {
  position: relative;
}

.input-width {
  width: 100%;
}

.input-with-icon .suffix-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.input-with-icon .suffix-icon.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 0;
}

.icon-container el-tooltip,
.icon-container img,
.icon-container .image-container {
  margin: 0 5px;
}

.image-container {
  position: relative;
  display: flex;
  align-items: center;
}

.hover-items {
  display: none;
  position: absolute;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.hover-items::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.image-container:hover .hover-items {
  display: block;
}

.image-container:hover .hover-items {
  display: block;
}

.hover-items div {
  padding: 5px 10px;
  cursor: pointer;
}

.hover-items div:hover {
  background-color: #f5f5f5;
}

.image-container img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.closeIcon {
  position: absolute;
  right: 15px;
  top: 30px;
  color: black;
  font-weight: 300;
}
</style>
